body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  /* position:absolute; */
  /* top: 80px;
  left: 250px;
  bottom: 0;
  right: 0; */
  border-radius: 8px;
  width: 100%;
  height: 97%;
  box-shadow: 0px 0px 4px 0px rgba(0, 7, 22, 0.14), 0px 1px 14px 0px rgba(65, 77, 92, 0.20);
  border-width: 0;
}
.css-jn01mi{
  margin-top: 0 !important;
}
.css-y1gt6f{
  margin-top: 0 !important;
}
.css-1o3oomb{
  margin-top: 0 !important;
}
.scanner-container {
  position: absolute;
  border: 1px solid #000000;
  width: 680px; /* Fullscreen width */
  height: 152px; /* Fullscreen height */

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1150;
  right: 10px;
  top:10px
}

.barcode-scanner canvas{
  display: none;
}
/* Semi-transparent overlay (camera preview simulation) */
.camera-overlay {
  position: relative;
  width: 100%;
  height: 100%;

}

/* Scanning frame */
.scan-box {
  flex-direction: column;
  position: absolute;

  width: 680px; /* Width of the scanning box */
  height: 150px; /* Height of the scanning box */
  border: 2px solid #000; /* White border for the scanning box */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255); /* Slightly transparent background inside */
  overflow: hidden; /* Hide scanning line outside the box */
  border-radius: 5px; /* Rounded corners */
}

/* Scanning line */
.scan-line {
  position: absolute;
  width: 100%;
  height: 3px; /* Thickness of the scanning line */
  background: rgba(0, 255, 0, 0.7); /* Green semi-transparent color */
  animation: scan-move 1.5s infinite ease-in-out;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title{background:#ffff!important}
.apexcharts-menu-item.exportPNG,.apexcharts-menu-item.exportSVG{display:none}

.palletBox{
  border:2px solid #51a6e8;
  height:90px;
  margin:10px;
  display: flex;
  /*padding:10px;*/
  cursor: pointer;
  margin-bottom: 0px;

}
.palletPanel{
  position:absolute;
  top:0;
  left:0;
  margin:10px;
  z-index:1300;
  background-color:#f9f6f6;
  border:1px solid #000;

}
.videoPanel{
  position:absolute;
  left:0;
  top:40px;
  border:1px solid #FFF
  /*object-fit: cover;*/
}
.side{
  color:#FFFFFF;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  cursor:pointer;
}
.closeButton{
  z-index:99999999999;
  position:absolute;
  color:#FF0000;
  right:0;
  background-color: #FF0000;
}
.panelSelection{
  background-color:#000000;
  color:#FFFFFF;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  cursor:pointer;
}
.captureButton{
  position:absolute;
  width:50px;
  border:1px solid #000;
  border-radius:50%;
  height:50px;
  bottom:0;
  background-color:#2776CC;
  z-index:9999
}
.barcode-scanner {
  flex-grow: 1;
  position: relative;
  background-color: black;
}
.panelImageSize{
  width: 60px;
  height: 55px;
}
@media (max-device-width: 1024px) {
  .css-staguu-MuiDrawer-docked {
    width: 0 !important;
  }
  .css-ofv0ci{
    margin-left: 0 !important;
  }
  .css-ntbaay{
    width: 0 !important;
  }
}
@media (max-device-width: 750px)  {
  .css-staguu-MuiDrawer-docked {
    width: 0 !important;
  }
  .css-ofv0ci{
    margin-left: 0 !important;
  }
  .css-ntbaay{
    width: 0 !important;
  }
}
@media (device-width: 912px) or (device-width: 1180px) {
  .MuiDrawer-docked {
    width: 0 !important;
  }
  .css-ofv0ci{
    margin-left: 0 !important;
  }
  .MuiDrawer-docked{
    width: 0 !important;
  }
  .css-ntbaay{
    width: 0 !important;
  }

}
@media (hover:none), (pointer:coarse) {
  .MuiDrawer-docked {
    width: 0 !important;
  }
  .css-ofv0ci{
    margin-left: 0 !important;
  }
  .css-10qgjif-MuiDrawer-docked{
    width: 0 !important;
  }
  .css-ntbaay{
    width: 0 !important;
  }

}
